<template>
  <div class="content-box">
    <div class="banner-box">
      <div class="banner-first">
        <div class="banner-second">
          <div class="third">

          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">关于我们</p>
            </div>
            <!-- <div class="category-left">
              <img
                class="category-pic"
                src="@/assets/image/index/pic_category.png"
                alt=""
              />
              <div class="category-text">
                关于我们
                <div class="category-line"></div>
              </div>
              <div class="category-letter">ABOUT US</div>
            </div> -->
          </div>

          <!-- <div class="info-first">
            <div class="title">北京华夏元道文化科技有限公司</div>
            <div class="text">         隶属于华夏出版社，是2016年出版社为探索体制改革创新和多元发展而设立的全资子公司。公司致力于传统文化、特殊教育类图书出版，运营面向中小学生的《课外阅读》杂志，承接残疾人事业相关的文化活动。 2020年，在中国残联康复部指导及中国残疾人康复协会的支持下，创立特殊儿童康复教育综合服务平台，致力于为特殊儿童及家庭、康复行业从业者提供政策、知识、资讯及服务。</div>
          </div> -->

          <div class="info-second">
            <!-- <div class="title">北京华夏元道文化科技有限公司</div> -->
            <div class="text-box">
               <div>
                <p class="text">北京华夏元道文化科技有限公司是中国残疾人联合会下属华夏出版社有限公司的全资子公司，2016年应体制改革创新及多元化发展而设立。公司秉承出版社“传播人道主义、弘扬华夏文化”的宗旨，立足于图书出版，实施数字化转型和融合发展战略，围绕残疾人事业服务和残疾人文化产业发展大局，打造“华夏元道”品牌，提升华夏出版社综合实力。业务范围包括图书策划与发行、在线教育与培训、技术研发等。旗下华夏云课堂列入国务院印发《“十四五”残疾人保障和发展规划》中的基础设施、信息化、科技创新重点项目。</p>
               </div>
            </div>
          </div>

          <div class="liner"></div>
          </div>
          <img class="img" src="@/assets/image/about/banner_first.png" alt="" />

        </div>
      </div>
    </div>

    <div class="main-box">
      <div class="main-first">
        <div class="transparent"></div>
        <div class="info-third">
          <div class="category-box">
            <div class="leftTitle">
              <p class="logo"></p>
              <p class="title">华夏云课堂</p>
            </div>
            <!-- <div class="category-left">
              <img
                class="category-pic"
                src="@/assets/image/index/pic_category.png"
                alt=""
              />
              <div class="category-text">
                华夏云课堂
                <div class="category-line"></div>
              </div>
              <div class="category-letter">HUAXIA CLASS</div>
            </div> -->
          </div>
          <div class="text">华夏云课堂由中国残疾人康复协会支持，华夏出版社主办，北京华夏元道文化科技有限公司建设并运营。2021年纳入国务院印发《“十四五”残疾人保障和发展规划》中的“基础设施、信息化和科技创新重点项目”。平台立足儿童康复教育行业的从业者以及有特殊需要儿童的家庭，通过提供高质量、实用、有价值的培训课程、图书、知识分享，致力于打造儿童康复专业人士及家长的终身学习社区。平台康复类课程均由中国残疾人康复协会组织专家审核。</div>
          <div class="liner"></div>
        </div>
      </div> 

      <div class="main-second">
        <div class="category-box">
          <div class="leftTitle">
            <p class="logo"></p>
            <p class="title">联系我们</p>
          </div>
            <!-- <div class="category-left">
              <img
                class="category-pic"
                src="@/assets/image/index/pic_category.png"
                alt=""
              />
              <div class="category-text">
                联系我们
                <div class="category-line"></div>
              </div>
              <div class="category-letter">CONTACT US</div>
            </div> -->
        </div>
        <div class="contact-box">
          <div class="circle">
            <img
                class="circle-img"
                src="@/assets/image/about/icon_address.png"
                alt=""
              />
            <div class="circle-title">公司地址</div>
            <div class="circle-text">北京市东城区东直门外香河园北里4号</div>
          </div>
          <div class="spot">
            <div class="spot-first"></div>
            <div class="spot-second"></div>
            <div class="spot-third"></div>
            <div class="spot-fourth"></div>
            <div class="spot-fifth"></div>
          </div>
          <div class="circle">
            <img
                class="circle-img"
                src="@/assets/image/about/icon_postal.png"
                alt=""
              />
            <div class="circle-title">邮政编码</div>
            <div class="circle-text">100028</div>
          </div>
          <div class="spot">
            <div class="spot-first"></div>
            <div class="spot-second"></div>
            <div class="spot-third"></div>
            <div class="spot-fourth"></div>
            <div class="spot-fifth"></div>
          </div>
          <div class="circle">
            <img
                class="circle-img"
                src="@/assets/image/about/icon_email.png"
                alt=""
              />
            <div class="circle-title">电子邮件</div>
            <div class="circle-text">class@hxph.com.cn
            </div>
          </div>
          <div class="spot">
            <div class="spot-first"></div>
            <div class="spot-second"></div>
            <div class="spot-third"></div>
            <div class="spot-fourth"></div>
            <div class="spot-fifth"></div>
          </div>
          <div class="circle">
            <img
                class="circle-img"
                src="@/assets/image/about/icon_phone.png"
                alt=""
              />
            <div class="circle-title">联系电话</div>
            <div class="circle-text">400-800-9002</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {

    }
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
};
</script>

<style lang="less" scoped>
.content-box {
  background-color: #f6f6fc;
  overflow: hidden;

  .category-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
      .category-left {
        position: relative;
        width: 180px;
        .category-pic {
          width: 44px;
          height: 55px;
        }
        .category-text {
          position: absolute;
          top: 9px;
          left: 14px;
          font-size: 28px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #333333;
        }
        .category-letter {
          position: absolute;
          bottom: 1px;
          left: 14px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #085164;
          line-height: 16px;
        }
        .category-line {
          display: inline-block;
          margin-left: 2px;
          width: 10px;
          height: 0px;
          opacity: 0.6;
          border: 1px solid @border-color;
          vertical-align: middle;
        }
      }
      .category-right {
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
        .change-pic {
          width: 13px;
          height: 12px;
          vertical-align: baseline;
        }
      }
  }

  // .title{
  //   margin-bottom: 10px;
  //   font-size: 24px;
  //   font-family: Source Han Sans CN-Medium, Source Sans CN;
  //   font-weight: 500;
  //   color: #333333;
  //   line-height: 36px;
  // }

  .text{
    font-size: 18px;
    font-family: Source Han Sans CN-Normal, Source Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 27px;
    // text-indent: 36px;
  }

  .liner{
    margin-top: 30px;
    width: 8px;
    height: 4px;
    background: #333333;
  }

  .banner-box {
    height: 410px;
    .banner-first{
      position: relative;
      width: 100%;
      height: 355px;
      background: linear-gradient(222deg, #15B7DD 0%, rgba(111, 219, 244, 0.99) 42%, rgba(68, 192, 221, 0.98) 69%,#02A0C6 100%);
      
      .banner-second{
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        top: 83px;
        margin: auto;
        padding: 40px 125px 40px 30px;
        width: 1400px;
        height: 326px;
        background: #FFFFFF;
        
        .info-first{
           margin-bottom: 20px;
        }
        .info-second{
           margin-bottom: 20px;
        }
        .text-box{
          display: flex;
          align-items: center;
          
        }
        .img{
          margin: 22px 0 0 132px;
          width: 386px;
          height: 229px;
        }
       
      }
      
    }
  }

  .main-box {
    margin: 16px auto 45px;

    .main-first{
      position: relative;
      margin-top: 16px;
      height: 514px;
      background-image: url('../../assets/image/about/banner_second.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      .transparent{
        position: absolute;
        top: 120px;
        left: 503px;
        width: 309px;
        height: 311px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.62);
      }
      .info-third{
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 55px 40px 30px;
        width: 837px;
        height: 334px;
        background-color: #fff;
      }
    }

    .main-second{
      margin-top: 16px;
      padding: 40px;
      height: 327px;
      background: #FFFFFF;
      .contact-box{
        display: flex;
        padding: 0 50px;
        .circle{
          display: flex;
          flex-direction: column;
          align-items: center;
          // justify-content: space-between;
          width: 100%;
          .circle-img{
            margin-bottom: 12px;
            width: 116px;
          }
          .circle-title{
            margin-bottom: 4px;
            font-size: 18px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            line-height: 27px;
          }
          .circle-text{
            width: 195px;
            font-size: 18px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 27px;
            text-align: center;
          }
        }
        .spot{
          display: flex;
          align-items: center;
          height: 116px;
          .spot-first{
            margin-right: 23px;
            width: 15px;
            height: 15px;
            background: #90DDEF;
            // opacity: 0.5;
            border-radius: 50%;
          }
          .spot-second{
            margin-right: 23px;
            width: 13px;
            height: 13px;
            background: #AAE5F3;
            // opacity: 0.4;
            border-radius: 50%;
          }
          .spot-third{
            margin-right: 23px;
            width: 11px;
            height: 11px;
            background: #C0ECF6;
            // opacity: 0.3;
            border-radius: 50%;
          }
          .spot-fourth{
            margin-right: 23px;
            width: 9px;
            height: 9px;
            background: #D8F3F9;
            // opacity: 0.2;
            border-radius: 50%;
          }
          .spot-fifth{
            width: 7px;
            height: 7px;
            background: #EEFAFC;
            // opacity: 0.1;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .content-box {
    .liner {
      display: none;
    }
    // .title {
    //   font-size: 20px;
    //   font-weight: 500;
    // }
    .text {
      line-height: 29px;
      font-size: 16px;
    }
    .banner-box {
      .banner-first {
        .banner-second {
          width: 1200px;
          padding-right: 80px;
          .img {
            margin-left: 84px;
          }
        }
      }
    }
    .category-box {
      .category-left {
        .category-pic {
          width: 40px;
        }
        .category-text {
          top: 11px;
          font-size: 24px;
        }
        .category-letter {
          bottom: 2px;
          font-size: 12px;
        }
      }
    }
    .main-box {
      .main-first {
        height: 441px;
        .transparent {
          top: 83px;
          left: 459px;
        }
        .info-third {
          width: 700px;
          height: 318px;
          padding: 40px 30px 30px 30px;
        }
      }
      .main-second {
        .contact-box {
          .circle {
            width: 165px;
            .circle-img {
              width: 108px;
            }
            .circle-title {
              font-size: 16px;
              font-weight: 500;
            }
            .circle-text {
              width: 165px;
              font-size: 14px;
              line-height: 21px;
            }
          }
          .spot {
            .spot-first {
              width: 12.55px;
              height: 12.55px;
              margin-right: 19px;
            }
            .spot-second {
              width: 10.88px;
              height: 10.88px;
              margin-right: 19px;
            }
            .spot-third {
              width: 9.2px;
              height: 9.2px;
              margin-right: 19px;
            }
            .spot-fourth {
              width: 7.53px;
              height: 7.53px;
              margin-right: 19px;
            }
            .spot-fifth {
              width: 5.86px;
              height: 5.86px;
            }
          }
        }
      }
    }
  }
}
</style>
